import React, { useState, useEffect } from 'react';
import { Typography, CircularProgress, Box, Paper, Grid, Button, Modal, Tooltip, IconButton } from '@mui/material';
import AttivitaInsertEdit from './AttivitaInsertEdit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close'; // Importa l'icona Close
import { Height } from '@mui/icons-material';
const moment = require('moment');

const Attivita = ({ idProgetto }) => {
    const [attivita, setAttivita] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [selectedAttivita, setSelectedAttivita] = useState(null); // Stato per l'attività selezionata

    const formatCurrency = (value) => {
        if (typeof value === 'number') {
            return new Intl.NumberFormat('it-IT', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2
            }).format(value);
        }
        return '€0,00';
    };

    function formatDate(dateString) {
        return moment(dateString).format('DD/MM/YY');
    }

    const getBackgroundColor = (att) => {
        if (att.idpagamento) {
            return '#32CD32'; // LimeGreen
        } else if (att.idfattura) {
            return '#ADDF9F'; // GreenYellow
        } else if (att.avanzamento === 100) {
            return '#ADD8E6'; // LightBlue
        } else {
            return '#D3D3D3'; // LightGray
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');

        fetch(`https://www.paoloniinformatica.it/papi/projects/getAttivita/${idProgetto}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setAttivita(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Errore nel recupero delle attività:', error);
                setLoading(false);
            });
    }, [idProgetto]);


    const handleOpenModal = (attivita = null) => {
        if (attivita && attivita.idfattura) return; // Non aprire il modal se l'attività è fatturata
        setSelectedAttivita(attivita);
        setOpenModal(true);
    };


    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedAttivita(null); // Resetta l'attività selezionata
    };

    const calculateTotal = () => {
        return attivita.reduce((sum, att) => sum + (att.valoreattivitaprogetto * att.quantita_attivita), 0);
    };
    
    const handleSave = () => {
        handleCloseModal();
        setLoading(true);
        fetch(`https://www.paoloniinformatica.it/papi/projects/getAttivita/${idProgetto}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setAttivita(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Errore nel recupero delle attività:', error);
                setLoading(false);
            });
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <div>
            <Box display="flex" justifyContent="flex-end" mb={2}>
                <Tooltip title="Aggiungi attività spot">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenModal()} // Apri il modal per aggiungere una nuova attività
                    />
                </Tooltip>
            </Box>

            {attivita.length > 0 ? (
                attivita.map((att, index) => (

                    <Paper
                    key={index}
                    elevation={3}
                    style={{
                        padding: '4px',
                        marginBottom: '1px',
                        backgroundColor: getBackgroundColor(att),
                        cursor: att.idfattura ? 'default' : 'pointer', // Disabilita il cursore a manina se l'attività è fatturata
                        transition: 'background-color 0.3s ease',
                    }}
                    onClick={() => handleOpenModal(att)}
                    onMouseEnter={(e) => {
                        if (!att.idfattura) {
                            e.currentTarget.style.backgroundColor = '#f5f500'; // Cambia il colore di sfondo al passaggio del mouse solo se non è fatturata
                        }
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = getBackgroundColor(att); // Ripristina il colore di sfondo originale
                    }}
                >
                        <Grid container alignItems="center" spacing={2} style={{ lineHeight: '12px' }}>
                            <Grid item xs={1}>
                                <Typography variant="attivitaVariant">
                                    {att.siglaattivita}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="attivitaVariantData" color="text.attivitadata">
                                    {formatDate(att.datachiusura)}
                                </Typography>
                            </Grid>

                            <Grid item xs={7}>
                                <Typography variant="attivitaVariant">
                                    {att.nomeattivita}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Box textAlign="right">
                                    <Typography variant="attivitaVariant" color="text.attivitavalore">
                                        {att.quantita_attivita}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                                <Box textAlign="right">
                                    <Typography variant="attivitaVariant" color="text.attivitavalore">
                                        {formatCurrency(att.valoreattivitaprogetto)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                                <Box textAlign="right">
                                    <Typography variant="attivitaVariant" color="text.attivitavalore">
                                        {formatCurrency(att.valoreattivitaprogetto*att.quantita_attivita)}
                                    </Typography>
                                </Box>
                            </Grid>
                        


                        </Grid>
                    </Paper>



                )
            
            
            
            )


                
            ) : (
                <Typography variant="attivitaVariant" color="textSecondary">
                    Nessuna attività associata.
                </Typography>
            )}
  
  <Box mt={2} display="flex" justifyContent="flex-end">
    <Typography variant="h6" color="textPrimary">
        Totale: {formatCurrency(calculateTotal())}
    </Typography>
</Box>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                BackdropProps={{
                    onClick: (e) => e.stopPropagation(),
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.white',
                        boxShadow: 24,
                        p: 0,
                        borderRadius: 2,
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="left"
                        p={2}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            backgroundColor: 'background.default',
                        }}
                    >
                        <Typography variant="h6" color="textPrimary">
                            {selectedAttivita ? 'Modifica Attività' : 'Aggiungi Nuova Attività Spot'}
                        </Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        p={2}
                        sx={{
                            overflow: 'auto',
                            maxHeight: '70vh',
                        }}
                    >
                        <AttivitaInsertEdit
                            idProgetto={idProgetto}
                            existingData={selectedAttivita} // Passa i dati esistenti se si sta modificando
                            onSave={handleSave}
                        />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default Attivita;
